.face-preview-container {
	position: relative;
	height: 480px;

	display: flex;
	justify-content: center;
	justify-items: center;
	align-items: center;
	align-content: center;

	.face-preview-center {
		.face-preview {
			position: relative;
		}
	}

	.face-image-default {
		background-color: whitesmoke;
		width: 30%;
	}

	.face-image-canvas {
		margin: 0 auto;
		max-height: 475px;
	}

	&:after{
		clear: both;
		content: "";
		display: block;
	}
}

.focus-bio-preview-action() {
	opacity: 1;
}

.biometric-actions-container {
	position: relative;
}

.biometric-actions-dependant {
	&:hover {
		+ .biometric-actions .bio-action {
			.focus-bio-preview-action();
		}
	}
}

@actions-container-height: 40px;

.biometric-actions {
	position: absolute;
	top: -@actions-container-height / 2;
	right: -20px;
	height: @actions-container-height;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	
	.bio-action {
		border-radius: 50%;
		box-shadow:
			rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
			rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
			rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
		opacity: 0.5;
		transition: opacity 0.2s linear;

		&:hover {
			.focus-bio-preview-action();
		}
	}
}