.kbd-custom {
  display: inline-block;
  border-radius: 3px;
  padding: 0 3px;
  box-shadow: 1px 1px 1px #777;
  margin: 0;
  font-size: small;
  background: #eee;
  color: #555;
  cursor: pointer;
  font-variant: small-caps;
  font-weight: 600;
  vertical-align: baseline;

  letter-spacing: 1px;

  user-select: none;

  &:hover, kbd:hover * {
    color: black;
  }

  kbd:active, kbd:active * {
    color: black;
    box-shadow: 1px 1px 0 #ddd inset;
  }

  kbd {
    padding: 0;
    margin: 0 1px;
    box-shadow: 0 0 0 black;
    vertical-align: baseline;
    background: none;
  }

  & kbd:hover {
    box-shadow: 0 0 0 black;
  }

  &:active kbd {
    box-shadow: 0 0 0 black;
    background: none;
  }

  &.kbd-dark,
  &.kbd-dark kbd {
    background: black;
    color: #ddd;
  }

  &.kbd-dark:hover,
  &.kbd-dark:hover *,
  &.kbd-dark kbd:hover {
    color: white;
  }
}

